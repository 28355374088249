<div class="row" *ngIf="this.showMenu">
    <div class="col-12 col-md-5 col-xl-6">
        <div class="row mx-0 d-none d-md-block">
            <label class="labelBlack">{{ gridTitle | translate }}</label>
        </div>
    </div>
    <div class="col-12 col-md-7 col-xl-6">
        <div class="row mx-0">
            <div *ngFor="let value of totalValues; index as i" class="col px-1">
                <ntw-title-value [titleValue]="value"></ntw-title-value>
            </div>
        </div>
    </div>
</div>
<div class="row py-2" *ngIf="this.showMenu">
    <div class="col-12 col-md-5">
        <kendo-textbox ntwExtended class="searchShared" placeholder="{{ 'shared.search' | translate }}" #search
            (valueChange)="onValueChange($event)" (afterValueChanged)="onAfterValueChanged($event)">
            <ng-template kendoTextBoxSuffixTemplate>
                <kendo-svg-icon [icon]="this.icons.search"></kendo-svg-icon>
            </ng-template>
        </kendo-textbox>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <kendo-grid #grid [data]="dataGridView" [navigable]="true" [resizable]="true" [sortable]="true"
            [loading]="loading" [pageable]="pageableOptions" [pageSize]="kendoGridState.take"
            [skip]="kendoGridState.skip" [sort]="kendoGridState.sort" [filter]="kendoGridState.filter"
            [rowSelected]="isRowSelected" [selectedKeys]="selectedKeys" [selectable]="this.selectableSettings"
            (dataStateChange)="dataStateChange($event)" (filterChange)="filterChange($event)"
            (cellClick)="onCellClick($event)" [kendoGridSelectBy]="this.idFieldName"
            [columnMenu]="{ filter: true, sort: true, columnChooser: false }">
            <kendo-grid-column *ngIf="this.showCheckBoxColumn && this.selectableSettings.enabled"
                [width]="40" [class]="{ 'text-center': true }"
                [resizable]="false" [columnMenu]="false">
                <ng-template kendoGridHeaderTemplate>
                    <div class="select-all-checkbox">
                        <input type="checkbox" kendoCheckBox
                        [disabled]="this.isSelectAllDisabled()"
                        [checked]="this.isAllSelected()"
                        [indeterminate]="this.isAnySelected() && !this.isAllSelected()"
                        (click)="this.onAllCheckboxClick()"/>
                    </div>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" kendoCheckBox 
                    [checked]="this.isEntitySelected(dataItem)"
                    (click)="this.onCheckboxClick(dataItem)"/>
                </ng-template>
            </kendo-grid-column>`
            <kendo-grid-column *ngFor="let col of columnSettings" [field]="col.field" [title]="col.title | translate"
                height="50" [width]="col.width ? col.width : 50"
                [minResizableWidth]="col.minResizableWidth ? col.minResizableWidth : 50"
                [sortable]="col.sortable === false ? col.sortable : true"
                [filterable]="col.filterable === false ? col.filterable : true" [filter]="col.filter"
                [format]="col.format" [editable]="col.editor ? true : false" [editor]="col.editor ? col.editor : null">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <ng-container *ngIf="col.loadingField && dataItem[col.loadingField]">
                        <kendo-loader class="me-1" size="small"></kendo-loader>
                    </ng-container>
                    <ng-container *ngIf="col.errorField && dataItem[col.errorField]">
                        <span *ngIf="col.valueOnError">{{ col.valueOnError | translate }}</span>
                        <span *ngIf="!col.valueOnError">{{ 'shared.unknown' | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="(!col.loadingField || (col.loadingField && !dataItem[col.loadingField]))
                                    && (!col.errorField || (col.errorField && !dataItem[col.errorField]))">
                        <ng-container *ngIf="col.translate">
                            <span>{{dataItem[col.field] | translate }}</span>
                        </ng-container>
                        <ng-container *ngIf="col.filter === 'boolean'">
                            <span *ngIf="dataItem[col.field] === true">{{ 'shared.true' | translate }}</span>
                            <span *ngIf="dataItem[col.field] === false">{{ 'shared.false' | translate }}</span>
                            <span *ngIf="!dataItem[col.field] && dataItem[col.field] !== false">{{ 'shared.unknown' | translate }}</span>
                        </ng-container>
                        <ng-container *ngIf="col.format === '{0:n0}'">
                            <span>{{dataItem[col.field] | number:'1.0-2':'sv-SE'}}</span>
                        </ng-container>
                        <ng-container *ngIf="col.format === '{0:n2}'">
                            <span>{{dataItem[col.field] | number:'1.2-2':'sv-SE'}}</span>
                        </ng-container>
                        <ng-container *ngIf="col.editor && !col.editorSettings.displayValue">
                        </ng-container>
                        <ng-container *ngIf="!col.translate
                                        && col.filter !== 'boolean'
                                        && col.format !== '{0:n0}'
                                        && col.format !== '{0:n2}'
                                        && !(col.editor && !col.editorSettings.displayValue)"
                        >
                            <ng-container *ngIf="isDate(dataItem[col.field])">
                                <span>{{dataItem[col.field] | date:col.format ?? 'dd/MM/yyyy'}}</span>
                            </ng-container>
                            <ng-container *ngIf="!isDate(dataItem[col.field])">
                                <span>{{dataItem[col.field]}}</span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-template>
                <ng-template *ngIf="col.editor === 'numeric'" kendoGridEditTemplate let-dataItem
                    style="height: 50px;">
                    <kendo-numerictextbox [formControl]="getFormControl(dataItem, col.field)"
                        [step]="col.editorSettings?.step ?? 1" [format]="col.editorSettings?.format ?? 'n2'"
                        [min]="col.editorSettings?.min" [max]="col.editorSettings?.max"
                        [decimals]="col.editorSettings?.decimals ?? 2"
                        [autoCorrect]="col.editorSettings?.autoCorrect ?? false" [value]="dataItem.value[col.field]">
                    </kendo-numerictextbox>
                </ng-template>
                <ng-template *ngIf="!col.filter || col.filter==='text'" kendoGridFilterMenuTemplate let-filter
                    let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [column]="column" [filter]="this.applyFilterCaseInvariance(col, filter)" [filterService]="filterService"
                        operator="contains">
                        <kendo-filter-contains-operator></kendo-filter-contains-operator>
                        <kendo-filter-eq-operator></kendo-filter-eq-operator>
                        <kendo-filter-neq-operator></kendo-filter-neq-operator>
                        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
                        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
                        <kendo-filter-isempty-operator></kendo-filter-isempty-operator>
                        <kendo-filter-isnotempty-operator></kendo-filter-isnotempty-operator>
                    </kendo-grid-string-filter-menu>
                </ng-template>
                <ng-template *ngIf="!col.filter || col.filter==='date'" kendoGridFilterMenuTemplate let-filter
                    let-column="column" let-filterService="filterService">
                    <kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService"
                        operator="contains">
                        <kendo-filter-isnotnull-operator> </kendo-filter-isnotnull-operator>
                        <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
                        <kendo-filter-after-operator></kendo-filter-after-operator>
                        <kendo-filter-before-operator></kendo-filter-before-operator>
                    </kendo-grid-date-filter-menu>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [width]="this.getButtonsColumnWidth()" [resizable]="false"
                *ngIf="this.showButtonsColumn">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="row flex-nowrap">
                        <div class="d-none d-xxl-block col"></div>
                        <div class="col-auto float-end" *ngIf="this.showOpenButton">
                            <button class="command-button" kendoButton look="flat"
                                (click)="this.onOpenButtonClicked(dataItem)">
                                <kendo-svg-icon [icon]="this.isItemEditable(dataItem) ? this.icons.pencil : this.icons.eye" class="button-icon"></kendo-svg-icon>
                            </button>
                        </div>
                        <div class="col-auto float-end" [ngClass]="{'ps-0': this.showOpenButton }"
                            *ngIf="this.showDeleteButton || this.showDeleteButtonFn(dataItem)">
                            <button class="command-button" kendoButton look="flat"
                                [disabled]="!this.isItemDeleteable(dataItem)"
                                (click)="this.onDeleteButtonClicked(dataItem)">
                                <kendo-svg-icon [icon]="this.icons.trash" class="button-icon"></kendo-svg-icon>
                            </button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</div>