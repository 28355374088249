/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CellClickEvent, DataStateChangeEvent, GridComponent, GridDataResult, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, State } from '@progress/kendo-data-query';
import { eyeIcon, pencilIcon, searchIcon, trashIcon } from '@progress/kendo-svg-icons';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChartSeries } from 'src/app/shared/constants/chart-series-colors';
import { CrmAccount } from 'src/app/shared/models/account';
import { CategorySum } from 'src/app/shared/models/graph/category-sum';
import { GraphData } from 'src/app/shared/models/graph/graph-data';
import { TitleValue } from 'src/app/shared/models/ui/title-value';
import { PageableOptions } from '../../constants/pageable-options';
import { ColumnSettings } from '../../models/ui/column-settings';


@Component({
  selector: 'ntw-grid-template',
  templateUrl: './grid-template.component.html',
  styleUrls: ['./grid-template.component.scss']
})
export class GridTemplateComponent implements AfterViewInit, OnChanges {
  icons = {
    eye: eyeIcon,
    pencil: pencilIcon,
    search: searchIcon,
    trash: trashIcon
  };

  @Input() gridTitle: string;
  @Input() graphTitle: string;
  @Input() totalValues: TitleValue[];
  @Input() idFieldName: string;
  @Input() currentAccount: CrmAccount;
  @Input() columnSettings: ColumnSettings[];
  @Input() showMenu = true;
  @Input() drillDownEnabled = true;
  @Input() loading: boolean;
  @Input() showCheckBoxColumn = true;
  @Input() showButtonsColumn = false;
  @Input() showOpenButton = false;
  @Input() showDeleteButton?: boolean = undefined;
  @Input() showDeleteButtonFn: (item) => boolean = () => false;
  @Input() entities: any[];
  @Input() selectedEntity: any;
  @Input() selectedEntities: any[];
  @Input() dataGridView: GridDataResult;
  @Input() summaries: CategorySum[];
  @Input() customNavigatePath: string = null;
  @Input() public dataStateChange: (state: DataStateChangeEvent) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Input() public filterChange: (filter: CompositeFilterDescriptor) => void = () => { };
  @Input() public generateSummary: (entities: any[]) => void;
  @Input() isItemEditable: (item) => boolean = () => false;
  @Input() deleteItem: (item) => void;
  @Input() itemViableForDelete: (item) => boolean;
  public pageableOptions = PageableOptions;

  public applyFilterCaseInvariance = (col: ColumnSettings, filter: CompositeFilterDescriptor) => {
    if (col.filterCaseSensitive === false) {
      return filter;
    }
    return this.applyFilterCaseInvarianceToAllFilters(filter);
  }

  private applyFilterCaseInvarianceToAllFilters = (filter: CompositeFilterDescriptor) => {
    filter.filters.forEach(f => {
      if ((f as FilterDescriptor).field) {
        (f as FilterDescriptor).ignoreCase = true;
      }
      else {
        this.applyFilterCaseInvarianceToAllFilters(f as CompositeFilterDescriptor);
      }
    });
    return filter;
  }

  @Output() selectedEntityChange = new EventEmitter<any>();

  @Input() public kendoGridState: State = {
    skip: 0,
    take: PageableOptions.pageSizes[0],
    filter: {
      logic: "and",
      filters: []
    }
  };

  @Input() public selectableSettings: SelectableSettings = {
    mode: 'multiple',
    drag: false,
    enabled: true,
  }

  @ViewChild('search', { read: ElementRef }) public searchBox: ElementRef;

  datepipe = new DatePipe(this.translateService.getBrowserCultureLang());

  public isDate(value: any): boolean {
    return value instanceof Date;
  }

  entitySearchHint = '';

  chartSeriesColor = ChartSeries.colors;
  graphData = new GraphData();

  private _selectedKeys: any[] = [];
  get selectedKeys(): any[] {
    return this._selectedKeys;
  }
  set selectedKeys(value: any[]) {
    this.selectedChanged(value);
    this._selectedKeys = value;
  }

  searchBoxText = '';

  @Output()
  public valueChange: EventEmitter<string> = new EventEmitter<string>();

  public onValueChange(query: string) {
    this.valueChange.emit(query);
  }

  @Output()
  public afterValueChanged: EventEmitter<string> = new EventEmitter<string>();

  public onAfterValueChanged(query: string) {
    this.afterValueChanged.emit(query);
  }

  @Input() onDoubleClick() {
    if (this.selectedEntity && this.drillDownEnabled && !this.customNavigatePath) {
      this.router.navigate([`./${this.selectedEntity[this.idFieldName]}`], { relativeTo: this.route });
    }
    else if (this.selectedEntity && this.customNavigatePath) {
      this.router.navigate([`${this.customNavigatePath}/${this.selectedEntity[this.idFieldName]}`]);
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService) {
  }

  ngAfterViewInit(): void {
    if (this.searchBox) {
      fromEvent(this.searchBox.nativeElement, 'keyup').pipe(debounceTime(1000)).subscribe(res => {
        const inputValue = res;
        this.searchBoxText = inputValue['target'].value;
      });
    }
    if (this.createFormGroup != null) {
      // timeout to call update after checks are done
      setTimeout(() => {
        this.updateEditedEntities();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const loadingChange = changes['loading'];
    if (loadingChange != undefined && loadingChange.previousValue === true && loadingChange.currentValue === false
      && (this.createFormGroup != null)) {
      this.selectedChanged(this.selectedKeys);
    }
  }

  @ViewChild('grid') private grid: GridComponent;
  public editedEntitiesInformation: { entityId: string, rowIndex: number, formGroup: UntypedFormGroup }[] = [];
  @Input() isEditingSelectedEntities = false;
  @Input() createFormGroup: (dataItem) => UntypedFormGroup;

  getFormControl(dataItem, field: string): UntypedFormControl {
    const entityInfo = this.editedEntitiesInformation.find(e => e.entityId == dataItem.value[this.idFieldName]);
    return <UntypedFormControl>entityInfo.formGroup.get(field);
  }

  public clearSelection() {
    if (this.isEditingSelectedEntities && this.createFormGroup != null) {
      this.editedEntitiesInformation.forEach(e => this.grid.closeRow(e.rowIndex));
      this.editedEntitiesInformation = [];
    }
    this.selectedEntities = [];
    this.selectedKeys = [];
  }

  public saveEditedEntities() {
    this.editedEntitiesInformation.forEach(entityInformation => {
      const entity = this.selectedEntities.find(e => e[this.idFieldName] == entityInformation.entityId)
      if (entity == null) {
        return;
      }

      this.grid.closeRow(entityInformation.rowIndex);
      const newValuesEntity = entityInformation.formGroup.value;
      for (const [key] of Object.entries(newValuesEntity)) {
        entity[key] = newValuesEntity[key];
      }
    });
  }

  public updateEditedEntities() {
    const isEditAllPresent = this.columnSettings.some(c => c.editorSettings?.editAll == true);
    if (this.createFormGroup === undefined || (this.selectedEntities === undefined && !isEditAllPresent)) {
      return;
    }

    const editedEntities = isEditAllPresent ? this.entities : this.selectedEntities;

    editedEntities.forEach(e => {
      if (-1 === this.editedEntitiesInformation.findIndex(entityInformation => entityInformation.entityId == e[this.idFieldName])) {
        const editingInfo = { entityId: e[this.idFieldName], rowIndex: -1, formGroup: this.createFormGroup(e) };
        this.editedEntitiesInformation.push(editingInfo);
      }
    });

    this.editedEntitiesInformation.forEach(entityInformation => {
      const arrayIndex = this.entities.findIndex(e => e[this.idFieldName] == entityInformation.entityId);
      this.grid.closeRow(entityInformation.rowIndex);
      entityInformation.rowIndex = arrayIndex === -1 ? arrayIndex : arrayIndex + this.grid.skip;
    });

    for (let i = 0; i < this.editedEntitiesInformation.length; i++) {
      const entityInformation = this.editedEntitiesInformation[i];
      if (-1 === editedEntities.findIndex(e => e[this.idFieldName] == entityInformation.entityId)) {
        this.grid.closeRow(entityInformation.rowIndex);
        this.editedEntitiesInformation.splice(i, 1);
        i--;
      }
    }

    this.editedEntitiesInformation.forEach(e => {
      if (e.rowIndex !== -1) {
        this.grid.editRow(e.rowIndex, e.formGroup);
      }
    });
  }

  isRowSelected = ($event: RowArgs): boolean => {
    return (this.selectedKeys?.indexOf($event.dataItem[this.idFieldName]) ?? 0) >= 0;
  }

  isEntitySelected = (entity: any): boolean => {
    return (this.selectedKeys?.indexOf(entity[this.idFieldName]) ?? 0) >= 0;
  }

  isAnySelected = (): boolean => {
    return this.entities?.some(e => this.selectedKeys?.includes(e[this.idFieldName]) ?? false) ?? false;
  }

  isSelectAllDisabled = (): boolean => {
    return this.entities?.length === 0 ?? true;
  }

  isAllSelected = (): boolean => {
    return this.entities && this.entities.length > 0 && this.entities.every(e => this.selectedKeys?.includes(e[this.idFieldName]) ?? false);
  }

  onAllCheckboxClick() {
    let selectedKeys = [...this.selectedKeys];

    if (this.isAllSelected()) {
      // remove all selected entities from the current page
      selectedKeys = selectedKeys.filter(id => !this.entities.find(e => e[this.idFieldName] === id));
    }
    else {
      // add all entities from the current page
      selectedKeys.push(...this.entities.filter(e => !selectedKeys.includes(e[this.idFieldName])).map(e => e[this.idFieldName]));
    }

    this.selectedKeys = selectedKeys.sort();
  }

  onCheckboxClick(entity: any) {
    if (!entity) {
      return;
    }

    let selectedKeys = [...this.selectedKeys];

    if (selectedKeys.find(id => id === entity[this.idFieldName])) {
      selectedKeys = selectedKeys.filter(id => id !== entity[this.idFieldName]);
    }
    else {
      selectedKeys.push(entity[this.idFieldName]);
    }

    this.selectedKeys = selectedKeys.sort();
  }

  /**
   * Handles the cellClick event of the Kendo UI grid.
   * @param $event The CellClickEvent object containing information about the clicked cell.
   */
  onCellClick($event: CellClickEvent) {
    if (!$event) {
      return;
    }

    let selectedKeys = [...this.selectedKeys];

    // Get the column settings for the clicked cell.
    const columnSettings = this.columnSettings[$event.columnIndex - 1];

    const clickedId = $event.dataItem[this.idFieldName];
    const isMultiselection = !!columnSettings?.multiselection;
    const isCtrlPressed = !!$event.originalEvent.ctrlKey;
    const isShiftPressed = !!$event.originalEvent.shiftKey;

    // If the clicked cell is not in the checkbox column and was double-clicked and the column is not editable
    if ($event.originalEvent.detail === 2 && !columnSettings?.editor && (!this.showCheckBoxColumn || (this.showCheckBoxColumn && $event.columnIndex !== 0))) {
      this.onDoubleClick();
      return;
    }
    else if (isShiftPressed && this.selectedEntity) {
      const lastSelectedId = this.selectedEntity[this.idFieldName];
      const lastSelectedIndex = this.entities.findIndex(e => e[this.idFieldName] === lastSelectedId);
      const clickedIndex = this.entities.findIndex(e => e[this.idFieldName] === clickedId);
      const minIndex = Math.min(lastSelectedIndex, clickedIndex);
      const maxIndex = Math.max(lastSelectedIndex, clickedIndex);
      selectedKeys = this.entities.slice(minIndex, maxIndex + 1).map(e => e[this.idFieldName]);
    }
    else if (isMultiselection || isCtrlPressed || (this.showCheckBoxColumn && $event.columnIndex === 0)) {
      if (selectedKeys.includes(clickedId)) {
        if (!columnSettings?.editor) {
          selectedKeys = selectedKeys.filter(id => id !== clickedId);
        }
      }
      else {
        selectedKeys.push(clickedId);
      }
    }
    else {
      selectedKeys = [clickedId];
    }

    this.selectedEntity = $event.dataItem;
    this.selectedKeys = selectedKeys.sort();

    // Emit the selectedEntityChange event with the updated selectedEntity property of the component.
    this.selectedEntityChange.emit(this.selectedEntity);
  }

  selectedChanged(selectedIds: string[]) {
    if (!selectedIds) {
      return;
    }
    if (!this.selectableSettings.enabled) {
      return;
    }

    this._selectedKeys = selectedIds;

    this.setSelectedEntitiesFromKeys();

    if (this.generateSummary) {
      this.generateSummary(this.selectedEntities);
    }

    if (this.createFormGroup != null) {
      this.updateEditedEntities();
    }
  }

  private setSelectedEntitiesFromKeys() {
    if (!this.selectedKeys) {
      return;
    }

    if (!this.selectedEntities) {
      this.selectedEntities = [];
    }
    else {
      // remove deselected entities
      for (let i = 0; i < this.selectedEntities.length; i++) {
        const selectedEntity = this.selectedEntities[i];
        if (selectedEntity && -1 === this.selectedKeys.findIndex(id => id === selectedEntity[this.idFieldName])) {
          this.selectedEntities.splice(i, 1);
          i--;
        }
      }
    }

    this.selectedKeys.forEach(id => {
      const selectedEntity = this.entities.find(entity => entity[this.idFieldName] === id);
      if (selectedEntity && -1 === this.selectedEntities.findIndex(e => e[this.idFieldName] === selectedEntity[this.idFieldName])) {
        this.selectedEntities.push(selectedEntity);
      }
    });
  }

  getButtonsColumnWidth(): number {
    if (this.showOpenButton && (this.showDeleteButton || this.entities?.some(this.showDeleteButtonFn))) {
      return 112;
    }
    if (this.showOpenButton || this.showDeleteButton || this.entities?.some(this.showDeleteButtonFn)) {
      return 62;
    }
    return 0;
  }

  @Input() onOpenButtonClicked(dataItem) {
    this.router.navigate([`./${dataItem[this.idFieldName]}`], { relativeTo: this.route });
  }

  onDeleteButtonClicked(dataItem) {
    const message = this.translateService.instant("alerts.confirmDelete");
    // eslint-disable-next-line no-alert
    if (confirm(message)) {
      this.deleteItem(dataItem);
      this.updateEditedEntities();
    }
  }

  isItemDeleteable(dataItem): boolean {
    return this.itemViableForDelete ? this.itemViableForDelete(dataItem) : this.showDeleteButton;
  }
}
