<ntw-confirm-dialog *ngIf="this.confirmClearCartDialogVisible" [close]="this.closeConfirmClearCartDialog"
    title="{{'orderProcess.clearCart' | translate | uppercase}}"
    message="{{'orderProcess.clearCartConfirmation' | translate}}" (decision)="this.onClearCartButtonClicked($event)">
</ntw-confirm-dialog>
<div class="row pt-3" *ngIf="!stepsLoading">
    <div class="col">
        <kendo-stepper [steps]="steps" steptype='full' [linear]="false" [(currentStep)]="currentStepIdx"
            (activate)="onStepChange($event)">
            <ng-template kendoStepperStepTemplate let-step>
                <div class='custom-step'>
                    <kendo-svg-icon *ngIf="step.icon" [icon]="step.icon"></kendo-svg-icon>
                    <span *ngIf="!step.icon">{{step.text}}</span>
                </div>
                <span>{{step.label}}</span>
            </ng-template>
        </kendo-stepper>
    </div>
</div>
<div class="row my-3 nimbus-sans-font" *ngIf="!stepsLoading">
    <div class="col-auto">
        <ng-container *ngIf="!isFirstStep()">
            <button kendoButton class="primaryButton fw-bold" [svgIcon]="this.icons.undo"
                (click)="onPreviousButtonClicked()">{{"orderProcess.moveBack" | translate}}
            </button>
        </ng-container>
    </div>

    <div class="col">
        <div class="float-end" *ngIf="!isLastStep()">
            <button kendoButton class="primaryButton fw-bold" [svgIcon]="this.icons.redo"
                [disabled]="!canProceedToStep(nextStep.stepName)" (click)="onNextButtonClicked()">
                {{"orderProcess.moveNext" | translate}}
            </button>
        </div>
        <div class="float-end pe-2" *ngIf="saveAsDraftButtonVisible()">
            <button kendoButton class="tertiaryButton fw-bold" [svgIcon]="this.icons.save"
                (click)="onSaveButtonClicked()">{{"orderProcess.saveChanges" | translate}}
            </button>
        </div>
        <div class="float-end pe-2" *ngIf="this.currentStep.stepName === 'warehouse' && !this.canSelectWarehouse">
            <button kendoButton class="tertiaryButton fw-bold" [svgIcon]="this.icons.cart"
                (click)="openConfirmClearCartDialog()">{{"orderProcess.clearCart" | translate}}
            </button>
        </div>
    </div>
</div>
<div class="row" *ngIf="loading; else processSteps">
    <ntw-loading-panel></ntw-loading-panel>
</div>
<ng-template #processSteps>
    <div class="row" *ngIf="orderUpdateInProgress">
        <ntw-loading-panel [coverTopBar]="true"></ntw-loading-panel>
    </div>
    <div class="row" *ngIf="currentStep.stepName === 'warehouse'">
        <div class="d-none d-lg-block col-1 col-xl-2"></div>
        <div class="col">
            <ntw-order-select-warehouse [isReadOnly]="!this.canSelectWarehouse" [(portalOrder)]="portalOrder"
                (portalOrderChange)="setIsOrderUpdated(true)">
            </ntw-order-select-warehouse>
        </div>
        <div class="d-none d-lg-block col-1 col-xl-2"></div>
    </div>
    <div class="row" *ngIf="currentStep.stepName === 'products'">
        <div class="col">
            <ntw-order-select-products class="py-1" [(portalOrder)]="portalOrder" [inventoryOnline]="inventoryOnline"
                (portalOrderChange)="onOrderUpdatedInSelectProductsStep()" [(searchValue)]="productSearchValue">
            </ntw-order-select-products>
        </div>
    </div>
    <div class="row" *ngIf="currentStep.stepName === 'summary'">
        <div class="col">
            <ntw-order-summary [(portalOrder)]="portalOrder" (portalOrderChange)="setIsOrderUpdated(true)"
                [pricesUpdatedOn]="pricesUpdatedOn" (getPricesStarted)="onGetPricesStarted()"
                (getPricesFinished)="onGetPricesFinished()" [isEverythingSelected]="verifyIfEverythingIsSelected()"
                [isCompanyNorway]="isCompanyNorway" [termsAndConditionsPath]="termsAndConditionsPath"
                (submitOrderClicked)="submitOrder()"></ntw-order-summary>
        </div>
    </div>
</ng-template>