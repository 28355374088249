import { CompositeFilterDescriptor, FilterDescriptor, State, toODataString } from '@progress/kendo-data-query';

export class KendoUtils {
    public static toODataString(state: State, additionalFilters?: CompositeFilterDescriptor): string {
        const deepCopy = JSON.parse(JSON.stringify(state));
        if (additionalFilters) {
            if (!deepCopy.filter) {
                deepCopy.filter = additionalFilters;
            }
            else {
                deepCopy.filter = {
                    logic: 'and',
                    filters: [deepCopy.filter, additionalFilters]
                };
            }
        }
        const updatedState = KendoUtils.updateFilterToLowercase(deepCopy);

        return toODataString(updatedState);
        // let oDataString = `$skip=${updatedState.skip ?? 0}&$top=${updatedState.take ?? 0}`;
        // if (updatedState.filter) {
        //     const filterODataString = this.getFilterODataString(updatedState.filter);
        //     if (filterODataString) {
        //         oDataString += `&$filter=${filterODataString}`;
        //     }
        // }
        // if (updatedState.sort) {
        //     const orderByODataString = this.getOrderByODataString(updatedState.sort);
        //     if (orderByODataString) {
        //         oDataString += `&$orderby=${orderByODataString}`;
        //     }
        // }
        // return oDataString;
    }

    private static updateFilterToLowercase(state: State): State {
        const func = (filter: CompositeFilterDescriptor) => {
            if (!filter) {
                return filter;
            }
            filter.filters.forEach(f => {
                if ((f as FilterDescriptor).field) {
                    if ((f as FilterDescriptor).ignoreCase && (f as FilterDescriptor).value) {
                        (f as FilterDescriptor).value = `${(f as FilterDescriptor).value}`.toLowerCase();
                    }
                }
                else {
                    func(f as CompositeFilterDescriptor);
                }
            });
            return filter;
        };
        state.filter = func(state.filter);
        return state;
    }

    // private static getFilterODataString(filter: CompositeFilterDescriptor | FilterDescriptor): string | undefined {
    //     if (!filter) {
    //         return '';
    //     }

    //     const compositeFilter = filter as CompositeFilterDescriptor;
    //     if (compositeFilter.filters && compositeFilter.filters.length > 1) {
    //         const filters = compositeFilter.filters.map(f => this.getFilterODataString(f)).filter(f => f != undefined);
    //         if (filters.length === 0) {
    //             return undefined;
    //         }
    //         else if (filters.length === 1) {
    //             return filters[0];
    //         }
    //         return `(${filters.join(` ${compositeFilter.logic} `)})`;
    //     }
    //     else if (compositeFilter.filters && compositeFilter.filters.length === 1) {
    //         return this.getFilterODataString(compositeFilter.filters[0]);
    //     }

    //     const simpleFilter = filter as FilterDescriptor;
    //     if (!simpleFilter.field || !simpleFilter.value) {
    //         return undefined;
    //     }

    //     const operator = (simpleFilter.operator instanceof Function ? simpleFilter.operator() : simpleFilter.operator) as string;
    //     let field = (simpleFilter.field instanceof Function ? simpleFilter.field() : simpleFilter.field) as string;
    //     field = simpleFilter.ignoreCase ? `tolower(${field})` : field;
    //     const value = simpleFilter.value as string;

    //     if (operator === 'isnull') {
    //         return `${field} eq null`;
    //     }
    //     if (operator === 'isnotnull') {
    //         return `${field} ne null`;
    //     }
    //     if (operator === 'isempty') {
    //         return `${field} eq ''`;
    //     }
    //     if (operator === 'isnotempty') {
    //         return `${field} ne ''`;
    //     }
    //     if (operator === 'contains') {
    //         return `contains(${field}, '${this.encodeODataString(value.toString())}')`;
    //     }
    //     if (operator === 'doesnotcontain') {
    //         return `not contains(${field}, '${this.encodeODataString(value.toString())}')`;
    //     }
    //     if (operator === 'startswith') {
    //         return `startswith(${field}, '${this.encodeODataString(value.toString())}')`;
    //     }
    //     if (operator === 'doesnotstartwith') {
    //         return `not startswith(${field}, '${this.encodeODataString(value.toString())}')`;
    //     }
    //     if (operator === 'endswith') {
    //         return `endswith(${field}, '${this.encodeODataString(value.toString())}')`;
    //     }
    //     if (operator === 'doesnotendwith') {
    //         return `not endswith(${field}, '${this.encodeODataString(value.toString())}')`;
    //     }
    //     return `${field} ${this.getODataFilterOperator(operator)} '${this.encodeODataString(value.toString())}'`;
    // }

    // private static getODataFilterOperator(operator: string): string {
    //     switch (operator) {
    //     case 'neq':
    //         return 'ne';
    //     case 'gte':
    //         return 'ge';
    //     case 'lte':
    //         return 'le';
    //     default:
    //         return operator as string;
    //     }
    // }

    // public static encodeODataString(value: string): string {
    //     return value.replace(/'/g, "''").replace(/%/g, '%25')
    //         .replace(/ /g, '%20')
    //         .replace(/!/g, '%21')
    //         .replace(/"/g, '%22')
    //         .replace(/#/g, '%23')
    //         .replace(/\$/g, '%24')
    //         .replace(/&/g, '%26')
    //         .replace(/\(/g, '%28')
    //         .replace(/\)/g, '%29')
    //         .replace(/\*/g, '%2A')
    //         .replace(/\+/g, '%2B')
    //         .replace(/,/g, '%2C')
    //         .replace(/-/g, '%2D')
    //         .replace(/\./g, '%2E')
    //         .replace(/\//g, '%2F')
    //         .replace(/:/g, '%3A')
    //         .replace(/;/g, '%3B')
    //         .replace(/</g, '%3C')
    //         .replace(/=/g, '%3D')
    //         .replace(/>/g, '%3E')
    //         .replace(/\?/g, '%3F')
    //         .replace(/@/g, '%40')
    //         .replace(/\[/g, '%5B')
    //         .replace(/\\/g, '%5C')
    //         .replace(/\]/g, '%5D')
    //         .replace(/_/g, '%5F')
    //         .replace(/`/g, '%60')
    //         .replace(/\{/g, '%7B')
    //         .replace(/\|/g, '%7C')
    //         .replace(/\}/g, '%7D')
    //         .replace(/~/g, '%7E');
    // }

    // private static getOrderByODataString(sort: SortDescriptor[]): string | undefined {
    //     if (!sort) {
    //         return undefined;
    //     }
    //     return sort.map(s => `${s.field} ${s.dir}`).join(',');
    // }
}
